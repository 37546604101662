






































































































































































































































































































































































































































































.tags-box {
  display: flex;

  .tabs-menu-top {
    flex: 1;
    overflow: hidden;
  }

  .person_operate {
    flex: none;
    display: inline-flex;
    height: 40px;
    margin: 1px 26px 0 0;
    color: #666;
    font-size: 14px;
    align-items: center;

    .panel-item {
      margin-left: 30px;

      i {
        color: #999;
      }
    }

    .el-form-item {
      margin: 0;
    }

    .el-input__inner {
      height: 28px;
      line-height: 28px;
    }

    .person_img {
      height: 34px;
      margin-left: 12px;

      img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
      }
    }

    .reset_pass_panel {
      margin-left: 26px;
      cursor: pointer;

      i {
        margin-right: 6px;
        font-size: 14px;
      }
    }

    .layout_panel {
      margin-left: 30px;
      cursor: pointer;

      i {
        margin-right: 6px;
        font-size: 14px;
      }
    }
  }
}

.tabs-menu-top {
  margin: 9px 30px 0 20px;

  .el-tabs__header {
    margin: 0;
    padding-bottom: 0;
    border-bottom: none;

    .el-tabs__nav-wrap {
      box-shadow: none;

      .el-tabs__nav-scroll {
        padding: 0 10px 20px;
      }

      .el-tabs__nav {
        height: 30px;
        border: none;

        .el-tabs__item {

          margin: 0 3px;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: normal;
          border: none;
          border-radius: 4px;
          color: #19b0b0;
          padding: 0 24px !important;
          overflow: hidden;
          margin: 0 20px 0 0;
          transition: none;

          .el-icon-close {
            position: absolute;
            width: 25px;
            height: 25px;
            padding: 9px 3px 0 0;
            font-size: 12px;
            font-weight: 600;
            color: #28cccc;
            text-align: center;
            top: -10px;
            right: -6px;
            opacity: 0;
            box-sizing: border-box;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            transition: opacity .2s;
            z-index: 999;

            &:hover {
              background: none;
            }

            &:before {
              transform: scale(.8);
            }
          }

          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 4px;
            box-sizing: border-box;
            border: 1px solid rgba(25, 176, 176, 0.50);
          }

          &.is-active {
            // border: none;
            color: #fff;
            // font-weight: bold;
            box-shadow: 0px 2px 10px 3px rgba(85, 195, 198, 0.3);
            background: linear-gradient(to right, #28CCCC, #73E2E2);

            .el-icon-close {
              opacity: 1;
              background: #ffff;
            }

            &:hover {
              color: #fff;
            }

            &:after {
              display: none;
            }
          }

          &:hover {
            .el-icon-close {
              opacity: 1;
            }
          }

        }
      }

      .el-tabs__nav-prev,
      .el-tabs__nav-next {
        line-height: 30px;
      }

      &.is-scrollable {
        margin-left: 10px;
      }

    }
  }
}

.updatePassDiaPanel {
  .closeDiaBtn:hover {
    background: white !important;
    color: #28cccc !important;
    border-color: #28cccc !important;
  }

  .el-form-item__label {
    width: 96px !important;
  }
}

