.el-button {
  padding: 7px 25px;
  font-size: 12px;
}
.el-button--default:focus,
.el-button--default:hover,
.el-button--plain:focus,
.el-button--plain:hover {
  color: #28cccc;
  border-color: #28cccc;
  background-color: #fff;
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  background-image: linear-gradient(-22deg,
    #28cccc 0%,
    #73e2e2 100%);
  border-radius: 4px;
  opacity: 0.4;
}

.el-button--primary {
  background: #28cccc;
  border-color: #28cccc;
}

.el-button--text {
  color: #333333;
  padding: 6px 10px;
}

.el-button--text:hover {
  color: #fff;
  background: #28cccc;
  -webkit-box-shadow: 1px 1px 18px -6px #28cccc;
  box-shadow: 1px 1px 18px -6px #28cccc;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: #61DDDD;
  border-color: #61DDDD;
  color: #FFF;
}

.el-message-box__btns {
  .el-button {    
    cursor: pointer;
    position: relative;
    display: inline-flex;
    min-width: 76px;
    height: 28px;
    padding: 0 12px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 4px;
    color: #999;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: ease .3s;
    vertical-align: middle;
    text-decoration: none;
    border: none;
    outline: none;
    white-space: nowrap;
    background: none;
    z-index: 0;

    &:before {
      position: absolute;
      content: '';
      border-radius: 100%;
      width: 0;
      height: 0;
      left: 50%;
      top: 50%;
      z-index: 0;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      background-size: 100% 100%;
      transition: width .3s ease 0s, padding .3s ease 0s, transform .3s ease 0s, opacity .3s ease 0s;
      background-image: linear-gradient(-22deg, rgba(40, 204, 204, 1) 0%, rgba(115, 226, 226, 1) 100%);
      z-index: -1;
    }

    &:after {
      opacity: 1;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      box-sizing: border-box;
      transition: ease .3s;
      z-index: -2;
    }

    &.el-button--primary{      
      color: #fff;
      background-image: linear-gradient(-22deg, rgba(40, 204, 204, 1) 0%, rgba(115, 226, 226, 1) 100%);
      &:before {
        box-shadow: inset 0 0 60px 0 rgba(255, 255, 255, 0.5);
      }
      &:after {
        border:none;
      }
      &:hover {
        color: #fff;

        &:before {
          width: calc(100% + 10px);
          padding-top: calc(100% + 10px);
        }
        &:after {}
      }
    }
  }
}
//表格里的操作按钮 start
.operate_btn_panel {
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  div {
    flex-wrap:wrap;
  }
  .disabled_btn {
    padding: 2px 14px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    margin: 0 1px;
    text-decoration: none;
    color: #ccc;
    margin: 0 1px;
    -webkit-transition: .1s;
    transition: .1s;
  }

  span {
    // color: #eaeaea;
  }

  .operate_btn {
    // padding: 2px 14px;
    // border-radius: 4px;
    // text-align: center;
    // cursor: pointer;
    // text-decoration: none;
    // color: #444;
    // margin: 0 1px;
    // transition: .1s;

    // &:hover {
    //   color: white;
    //   background: #28CCCC;
    //   box-shadow: 1px 1px 18px -6px #28CCCC;
    // }
  }
}
.el-table {
  td {
    .z-btn {
      margin: 0 5px 0 6px;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        width:1px;
        height: 14px;
        top: 50%;
        left: 0;
        margin:-7px 0 0 -6px;
        background: #EAEAEA;
      }
      &:first-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
.el-button--danger:focus, .el-button--danger:hover{
  background: #F56C6C;
  border-color: #F56C6C;
}
//表格里的操作按钮 end
