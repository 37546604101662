.avue-sidebar {
  padding-top: 64px;
  height: 100%;
  position: relative;
  transition: width .6s;
  box-sizing: border-box;
  background: #fff;

  .el-menu {
    padding: 0 14px;

    .el-submenu {
      margin: 5px 0;

      .el-submenu__title {
        font-size: 14px;
        height: 38px;
        line-height: 38px;
        padding-left: 5px !important;
        color: #666 !important;
        border-radius: 4px;
        background: #fff !important;

        &:hover {
          background: #F4F6F5 !important;
        }
      }

      &.is-active {
        .el-submenu__title {
          background: #F4F6F5 !important;
        }
      }

      .el-menu {
        padding: 0 !important;

        .el-menu-item {
          display: block;
          width: 100%;
          min-width: auto !important;
          height: 38px;
          line-height: 38px;
          margin: 10px 0;
          padding-left: 34px !important;
          color: #666 !important;
          border-radius: 4px;
          background: #fff !important;
          transition: none !important;

          &:hover {
            background: #F4F6F5 !important;
          }

          &.is-active {
            color: #fff !important;
            background: linear-gradient(90deg, #28CCCC 0%, #73E2E2 100%) !important;
            box-shadow: 0px 2px 10px 0px rgba(85, 195, 198, 0.56);
          }
        }
      }
    }
  }
}

.el-menu--vertical {
  .el-menu-item {
    margin: 10px 10px;
    height: 38px !important;
    line-height: 38px !important;
    color: #666 !important;
    border-radius: 4px;
    background: #fff !important;
    transition: none;

    &.is-active,
    &:hover {
      color: #fff !important;
      background: linear-gradient(90deg, #28CCCC 0%, #73E2E2 100%) !important;
      box-shadow: 0px 2px 10px 0px rgba(85, 195, 198, 0.56);
    }
  }
}
