@font-face {
  font-family: "iconfont"; /* Project id 2984341 */
  src: url('iconfont.woff2?t=1639034494222') format('woff2'),
       url('iconfont.woff?t=1639034494222') format('woff'),
       url('iconfont.ttf?t=1639034494222') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zhuxiaomoren1:before {
  content: "\e605";
}

.xiugaimimamoren1:before {
  content: "\e604";
}

.shijian:before {
  content: "\e6a4";
}

.dizhi:before {
  content: "\e6a3";
}

.xiugai:before {
  content: "\e6a2";
}

.shujubaobiao:before {
  content: "\e6a1";
}

.shangchuantupian:before {
  content: "\e6a0";
}

.shangchuan:before {
  content: "\e69e";
}

.liuyanhuifu:before {
  content: "\e69d";
}

.fabuhuodong:before {
  content: "\e69c";
}

