// 重置
@import './reset.scss';
// 全局变量
@import './variables.scss';
// ele样式覆盖
@import './element-ui.scss';
// 顶部右侧显示
@import './top.scss';
// 导航标签
@import './tags.scss';
// 工具类函数
@import './mixin.scss';
// 侧面导航栏
@import './sidebar.scss';
//主题
// @import './theme/index.scss';
//按钮
@import './defaultButton.scss';
//适配
@import './media.scss';
//弹窗
@import './defaultDialog.scss';
//表格
@import './defaultTable.scss';

@include scrollBar();
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: 500;
// }


// body,
// div,
// dl,
// dt,
// dd,
// ul,
// ol,
// li,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// pre,
// form,
// fieldset,
// input,
// p,
// blockquote,
// th,
// td {
//   margin: 0;
//   padding: 0;
// }

// * {
//   outline: none !important;
// }

// body {
//   font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
//   line-height: 1.5;
// }

// html,
// body {
//   height: 100%;
// }

// fieldset,
// img {
//   border: 0;
// }

// ol,
// ul {
//   list-style: none;
// }

// em {
//   font-style: normal;
// }

// input,
// button,
// select,
// textarea {
//   outline: none;
// }

// textarea {
//   resize: none;
// }

// //为了使文本段落左右两边对齐
// p {
//   word-wrap: break-word
// }

// a {
//   text-decoration: none;
// }

// i {
//   display: inline-block;
// }


// /*a:hover { color: #f60; text-decoration:none; }*/

// address,
// cite,
// code,
// em,
// th {
//   font-weight: normal;
//   font-style: normal;
// }

// a {
//   color: inherit;
//   text-decoration: none;
//   display: inline-block;
// }


// /*a:link{color:#009;}*/


// /*a:visited{color:#800080;}*/

// a:hover,
// a:active,
// a:focus {
//   text-decoration: underline;
// }

// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }
::-webkit-input-placeholder,
:-ms-input-placeholder,
::placeholder {
  color: #999 !important;
  font-size: 14px;
  font-family: 'Microsoft Yahei';
}

.hidden {
  display: none;
}

.padding {
  padding: 0 !important;
}

.margin {
  margin: 0 !important;
}

.inline-flex {
  display: inline-flex;
}

.pull-right {
  float: right !important;
  ;
}

.pull-left {
  float: left !important;
}

.pull-center {
  text-align: center;
}

.pull-flex {
  display: flex;
}

.pull-overflow {
  overflow: hidden;
}

.pull-auto {
  height: auto;
  overflow: hidden;
}

.pull-height {
  height: 100%;
  overflow: hidden;
}

.pull-fixed {
  position: fixed;
  left: 0;
  top: 0;
}

.iframe {
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.text-white {
  color: #fff;
}

.grayMode {
  filter: grayscale(100%);
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

//main-container全局样式
.app-main {
  min-height: 100%
}

.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.app-container {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.pagination-container {
  padding: 20px 0;
  float: right;
}

.defaultColor {
  color: rgb(40, 204, 205)
}

.flex {
  display: flex;
}

.flex_item {
  flex: 1
}

.textB {
  font-weight: 600;
}

.red {
  margin: 0 1px;
  color: #F15037;
  cursor: pointer;
}

.mrL20 {
  margin-left: 20px;
}

.avue-view .el-tabs__item.is-active {
  color: #28cccc !important;
}

.el-tooltip__popper {
  &.is-dark {
    max-width: 332px;
    min-width: 40px;
    padding: 7px 16px;
    line-height: 1.5em;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 12px;
    background-color: rgba(85, 85, 85, .9);
    box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, .6);

    &.copyTextPanel {
      border-radius: 2px 4px 4px 2px;
      padding: 0;

      >div {
        display: flex;

        span {
          line-height: 18px !important;
          overflow: hidden;
          padding: 8px 16px;
        }

        .btnCopy {
          cursor: pointer;
          padding: 8px 12px 8px 14px;
          border-radius: 2px;
          // line-height: 32px;
          background: rgba(40, 204, 204, 1.00);
        }
      }
    }

    &[x-placement^=top] {
      .popper__arrow {
        border-top-color: rgba(85, 85, 85, .9);

        &::after {
          border-top-color: transparent;
        }
      }
    }

    &[x-placement^=bottom] {
      .popper__arrow {
        border-bottom-color: rgba(85, 85, 85, .9);

        &::after {
          border-bottom-color: transparent;
        }
      }
    }

    &[x-placement^=left] {
      .popper__arrow {
        border-left-color: rgba(85, 85, 85, .9);

        &::after {
          border-left-color: transparent;
        }
      }
    }

    &[x-placement^=right] {
      .popper__arrow {
        border-right-color: rgba(85, 85, 85, .9);

        &::after {
          border-right-color: transparent;
        }
      }
    }
  }

  &.is-light {
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.6);

    &[x-placement^=top] {
      .popper__arrow {
        border-top-color: #fff;
      }
    }

    &[x-placement^=bottom] {
      .popper__arrow {
        border-bottom-color: #fff;
      }
    }

    &[x-placement^=left] {
      .popper__arrow {
        border-left-color: #fff;
      }
    }

    &[x-placement^=right] {
      .popper__arrow {
        border-right-color: #fff;
      }
    }
  }
}



// end

.el-menu {
  background: white !important;
}

.el-tabs__item:hover {
  color: #28cccc;
}

.el-tabs__active-bar {
  background: #28cccc !important;
}

.el-input--mini .el-input__inner {
  // height: 33px!important;
  // line-height: 33px!important;
  height: 28px; //cc
  line-height: 28px; //cc
}

.el-range-editor--small.el-input__inner {
  height: 30px !important;
  line-height: 28px;
}

.el-tabs__item.is-active {
  color: #28cccc;
  color: $mainBg;
}

.el-tabs__item:hover {
  color: #28cccc;
}

// .el-pagination.is-background .el-pager li:not(.disabled):hover {
//   color: #28cccc;
// }

// .el-pagination.is-background .el-pager li:not(.disabled):hover {
//   color: #28cccc;
// }

// .el-pagination.is-background .btn-next,
// .el-pagination.is-background .btn-prev,
// .el-pagination.is-background .el-pagination__sizes,
// .el-pagination.is-background .el-pager li {
//   margin: 0 4px;
//   height: 30px;
//   line-height: 30px;
//   border-radius: 50%;
// }

// .el-pagination span.el-pagination__total {
//   height: 30px;
//   line-height: 30px;
//   margin: 0 8px;
// }

// .el-pagination.is-background .el-pager li:not(.disabled).active {
//   background-color: #28cccc !important;
//   color: #FFF !important;
// }

// .el-pagination.is-background .el-pager li {
//   border-radius: 50% !important;
// }

// .el-pagination {
//   .el-pagination__editor {
//     .el-input__inner {
//       height: 30px;
//       line-height: 28px;
//       border-radius: 15px;
//     }
//   }

//   .el-select {

//     .el-input {
//       .el-input__inner {
//         height: 30px;
//         line-height: 28px;
//         padding-bottom: 1px;
//         border-radius: 15px;
//       }
//     }
//   }
// }

// .el-pager li {
//   height: 30px !important;
//   line-height: 30px !important;
// }

// .el-select-dropdown__item.selected {
//   color: #28cccc;
// }

// .el-select .el-input__inner:focus {
//   border-color: #28cccc;
// }

// .el-pagination__sizes .el-input .el-input__inner:hover {
//   border-color: #28cccc;
// }

// .el-select .el-input.is-focus .el-input__inner {
//   border-color: #28cccc;
// }

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #28cccc;
  outline: 0;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #28cccc !important;
}

.el-input__inner {
  -moz-user-select: auto !important;
  -webkit-user-select: auto !important;
  user-select: auto !important;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #606266;
  }
}

.el-input__inner:focus {
  border-color: #28cccc !important;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #28cccc;
  outline: 0;
}

.el-range-editor--small .el-range-separator {
  line-height: 29px;
  width: 22px;
}

// .el-date-table td.available:hover {
//   color: #28cccc !important;
// }

.el-range-editor.el-input__inner {
  padding: 0 0 0 10px !important;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: #28cccc !important;
}

.el-tabs__active-bar {
  background-color: #28cccc !important;
}

// .el-date-editor--daterange.el-input__inner {
//   width: 279px !important;

// }

// .el-date-editor .el-range__icon {
//   margin-left: 0px!important;
//   color: #28cccc !important;
//   font-weight: 600;
// }

 .el-date-editor .el-range__close-icon {
   margin-right: 28px !important;
 }


.el-tabs__item {
  font-size: 16px;
}

.el-tabs__nav-wrap {
  box-shadow: 0px 12px 24px -17px #999;
}

.el-loading-mask {
  background-color: none !important;
}

.el-select-dropdown__item.selected {
  color: #28cccc !important;
}

// .el-textarea__inner::-webkit-input-placeholder {
//   color: #999999;
// }

.el-textarea__inner {
  height: 96px !important;
  background-color: #EAEAEA !important;
  border: none;
  color: #343434;
}

.el-textarea__inner:focus {
  border-color: #28cccc !important;
}

.el-picker-panel__shortcut:hover {
  color: #28CCCC !important;
}

.el-tabs__item.is-disabled {
  color: #303133 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner.is-disabled {
  background-color: #F2F6FC;
  border-color: #DCDFE6;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #28cccc;
  border-color: #28cccc;
}

.el-checkbox__inner:hover {
  border-color: #28cccc !important;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #28cccc !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #28cccc;
}

.el-checkbox-group {
  margin-top: -5px;
}

.el-checkbox.is-bordered.is-checked {
  border-color: #28cccc;
}

.el-checkbox.is-bordered.is-checked.is-disabled {
  border-color: #ebeef5;
}

.el-dropdown-link {
  // color: #28cccc!important;
  color: #28cccc; //cc
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ecf5ff;
  color: #28cccc;
}

.el-tree-node.is-current>.el-tree-node__content {
  color: #28cccc;
  background-color: #f5f7fa;
}

.avue-main {
  background: #f8f8f8;
  left: 200px;
  width: calc(100% - 200px);
  height: calc(100%);
}

.avue-contail {
  background: #f8f8f8;
}

.avue-left {
  width: 200px;
}

@media screen and (max-width: 992px) {
  .avue-main {
    //left: 0;
    width: 100%;
  }
}

/*
   分页样式修改 start
 */
// .el-pagination.is-background .btn-next,
// .el-pagination.is-background .btn-prev,
// .el-pagination.is-background .el-pager li:last-child {
//   margin: 0;
// }
// .el-pager {
//   background: #ECECEC;
//   -webkit-border-radius: 15px;
//   -moz-border-radius: 15px;
//   border-radius: 15px;
//   margin: 0 7px
// }
// .el-pagination .btn-next:hover,
// .el-pagination .btn-prev:hover {
//   color: #28cccc;
// }
// .el-pagination.is-background .btn-next,
// .el-pagination.is-background .btn-prev,
// .el-pagination.is-background .el-pagination__sizes,
// .el-pagination.is-background .el-pager li {
//   margin: 0 4px;
//   height: 30px;
//   line-height: 30px;
//   border-radius: 50%;
// }
/*
   分页样式修改 end
 */

.el-submenu__icon-arrow {
  right: 13px
}

.el-range-editor--small .el-range-input {
  width: 95px;
}


.mt30 {
  margin-top: 30px
}

// .copyTextPanel {
//   padding: 0 5px 0 12px;
//   // border-radius: 0px !important;

//   .btnCopy {
//     float: right;
//     background: #28CCCD;
//     line-height: 21px;
//     color: white;
//     padding: 7px 14px;
//     margin-left: 8px;
//     cursor: pointer;
//     transform: translate(5px);
//   }
// }

.inBlock {
  display: inline-block;
}

.normal_btn {
  border: 1px solid rgba(25, 176, 176, 0.5) !important;
  color: #19b0b0;
  border-radius: 5px;
  border: 1px solid;
  -webkit-transition: .25s;
  transition: .25s;
  cursor: pointer;
  padding: 5px 31.5px;
}

.normal_btn:active {
  border-color: transparent;
}

.normal_btn:hover,
.normal_btn:focus {
  border-color: transparent !important;
  background: linear-gradient(to right, #6FE0E1, #32CFCF);
  color: white;
}

.ani_dialog {
  animation: tanchuang .45s;
}

.avue-crud__pagination {
  height: 60px !important;
}


@keyframes tanchuang {
  0% {
    transform: translateY(-80px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.el-menu-item .el-tooltip {
  padding: 0px 10px !important;
}

//iview toop修改
.ivu-el-tooltip-inner {
  font-size: 12px !important;
  padding: 0 8px 0 8px !important;
  line-height: 34px;
  border-radius: 6px !important;
  max-width: 1000px !important;
  background-color: rgba(0, 0, 0, .78) !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #28cccc;
  background: #28cccc;
}

.el-radio__input.is-checked+.el-radio__label {
  color: #28cccc;
}

.el-message-box__headerbtn:focus .el-message-box__close,
.el-message-box__headerbtn:hover .el-message-box__close {
  color: #28cccc;
}

.mt20 {
  margin-top: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px;
}

// .el-date-table td.current:not(.disabled) span {
//   background-color: #28cccc;
// }

.el-message {
  z-index: 20000 !important;
}

.el-picker-panel {
  z-index: 3001 !important;
}

.el-select-dropdown {
  z-index: 3001 !important;
}

.el-select-dropdown .popper__arrow {
  display: none;
}

.el-select-dropdown {
  border: 1px solid #28cccc
}

.el-select-dropdown {
  margin-top: 5px !important;
}

.el-select-dropdown__item {
  margin: 0 0px 0px -3px;
  border-radius: 3px;
}

.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  text-align: left !important;
}

.el-select-dropdown__item:hover {
  background: #DFF7F7;

  span {
    color: #19B0B0;
    font-weight: 600;
  }
}

.el-select-dropdown__item.selected {
  background: #DFF7F7;
}

.el-select-dropdown__item.selected span {
  color: #19B0B0;
  font-weight: 600;
}

// 取消原因统一弹窗   start
.ivu-el-tooltip-inner {
  .mod {
    position: relative;
    width: 402px;
    height: auto;
    min-height: 170px;
    padding: 3px 0 15px 0;
    z-index: 2;
    box-sizing: border-box;
  }

  .cancelreason_popup_title {
    margin-left: 122px;
    margin-top: -8px;
  }

  .cancel_deadDetail_content {
    margin-left: 10px;
    width: 100%;

    .reject_text .reject_text_company {
      color: white;
      text-align: right;
      line-height: 21px;
    }

    .reject_text .reject_text_reason {
      padding: 8px;
      background: #A5EDED;
      color: #222;
      line-height: 20px;
      max-width: 212px;
      min-width: 146px;
      -webkit-box-shadow: 1px 13px 14px -17px #999;
      box-shadow: 1px 13px 14px -17px #999;
      border-radius: 8px 0px 8px 8px;
      white-space: normal;
    }

    .reject_text_time {
      width: 34%;
      text-align: center;
      border-radius: 5px;
      margin: 22px 0 2px 119px;
      opacity: .8;
      line-height: 8px;
      color: rgba(255, 255, 255, .6);
      padding: 5px;
      background: #5A5A5A;
    }

    .reject_person {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      margin-left: 9px;
      background: #EEEEEE;
      color: #48AFAF;
    }

    .send_text_time {
      width: 34%;
      text-align: center;
      margin: 2px 117px 8px 117px;
      border-radius: 5px;
      opacity: .8;
      line-height: 8px;
      color: rgba(255, 255, 255, .6);
      padding: 5px;
      background: #5A5A5A;
    }

    .send_text {
      line-height: 20px;
      padding: 0 10px;

      .send_text_reason {
        padding: 8px;
        color: #222;
        max-width: 212px;
        background: #EDEDED;
        box-shadow: 1px 8px 21px -11px #28CCCC;
        border-radius: 0 8px 8px 8px;
        min-width: 170px;
        white-space: normal;
      }

      .send_text_company {
        color: white;
      }
    }

    .float_left {
      /* float:left; */
      display: flex;
      justify-content: flex-start;
      clear: both;
    }

    .float_right {
      display: flex;
      justify-content: flex-end;
      margin: 10px 15px 10px 10px;
    }

    .send_person {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      background: #28CCCC;
      color: white;
    }
  }
}

//取消原因统一弹窗   end


.tanchuang-enter-active,
.tanchuang-leave-active {
  transition: .4s
}

.tanchuang-enter,
.tanchuang-leave-active {
  opacity: 0;
  transform: translateY(-60px)
}


//  图片组件样式修改 start
.el-image-viewer__close {
  color: white;
}

.el-image-viewer__actions,
.el-image-viewer__next,
.el-image-viewer__prev {
  background: rgba(0, 0, 0, .3);
}

// 图片组件样式修改  end


// start 分页 xl
/* s分页器自定义样式 */

.el-pagination {
  margin: 30px auto;
  text-align: center;
  color: #666;
  font-size: 12px;
  font-weight: normal;
}

.el-pagination .el-pager li:not(.disabled):hover {
  color: #28cccc;
}

.el-pagination .el-pager li:not(.disabled):hover {
  color: #28cccc;
}

.el-pagination .btn-next,
.el-pagination .btn-prev,
.el-pagination .el-pager li {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  padding: 0;
  min-width: 30px !important;
  font-size: 12px;
}

.el-pagination .el-pager li:not(.disabled).active {
  background-color: #28cccc !important;
  color: #FFF !important;
}

.el-pagination .el-pager li {
  border-radius: 50% !important;
}

.el-pagination__sizes .el-input .el-input__inner {
  font-size: 12px;
}

.el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #28cccc;
}

.el-pagination .btn-next,
.el-pagination .btn-prev,
.el-pagination .el-pager li {
  margin: 0 6px 0 0;
  background-color: rgba(236, 236, 236, 0.5);
}

.el-pagination .btn-next,
.el-pagination .btn-prev,
.el-pagination .el-pager li:last-child {
  margin: 0;
}

.el-pagination__sizes .el-select .el-input .el-select__caret.is-reverse {
  color: #28cccc;
}

.el-pagination .btn-next,
.el-pagination .btn-prev,
.el-pagination .el-pager li {
  background: rgba(236, 236, 236, 0.5) !important;
}

.el-pagination__sizes .el-select .el-input.is-focus .el-input__inner {
  border: solid 1px #eaeaea !important;
}

.el-pager {
  background: #ECECEC;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  margin: 0 6px
}

.el-pager li {
  height: 30px !important;
  line-height: 30px !important;
}

.el-pagination .el-pager li:not(.disabled).active,
.btn-prev,
.btn-next {
  border-radius: 50% !important;
}

.el-pagination .btn-next:hover {
  background-color: #28cccc !important;
  color: #FFF !important;
}

.el-pagination .btn-prev:hover {
  background-color: #28cccc !important;
  color: #FFF !important;
}

.el-pagination .el-pager li:hover {
  background-color: none !important;
  color: #19b0b0 !important;
}

.el-pagination__sizes .el-input__inner {
  border-color: #eaeaea !important;
}

.el-pagination .el-select .el-input {
  width: 115px;
}

.el-pagination .el-select .el-input .el-input__inner {
  height: 30px;
  line-height: 28px;
  padding-bottom: 1px;
  border-radius: 15px;
  /* width: 107px; */
}

.el-pagination .el-pagination__editor .el-input__inner {
  height: 30px !important;
  line-height: 28px !important;
  border-radius: 15px !important;
}

.el-pagination button,
.el-pagination span:not([class*=suffix]) {
  margin-left: 7px;
  font-size: 12px;
}

.el-pagination__sizes {
  margin: 0 1px 0 0;
}

.paginationComCls .el-select-dropdown__item {
  font-size: 12px;
}

.paginationComCls .el-select-dropdown__item.selected {
  font-weight: normal;
}

.el-pagination .btn-next,
.el-pagination .btn-prev {
  color: #666;
}

.el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  color: #666;
}

.el-pagination .btn-prev[disabled]:hover {
  background-color: rgba(236, 236, 236, 0.5) !important;
}

.el-pagination .btn-next[disabled]:hover {
  background-color: rgba(236, 236, 236, 0.5) !important;
}

.paginationComCls.el-select-dropdown {
  min-width: 117px !important;
}

/* 浅色背景样式 */
.undertintBG .el-pager li {
  background-color: #f5f5f5;
}

.undertintBG .el-pager li {
  background: #f5f5f5 !important;
}

.undertintBG .el-pager {
  background: #f5f5f5;
}

.undertintBG .btn-next,
.undertintBG .btn-prev,
.undertintBG .el-pager li {
  background-color: #f5f5f5 !important;
}

/* e分页器自定义样式 */

// end 分页 xl


/* s ele 下拉框样式 */
.el-select-dropdown {
  border: 1px solid #28cccc !important;

}

.el-select-dropdown__item:hover {
  background: #dff7f7 !important;
}

.el-select-dropdown[x-placement^=top] .popper__arrow {
  opacity: 0;
}

.el-select-dropdown[x-placement^=top] {
  margin-bottom: 6px;
}

.el-select-dropdown[x-placement^=bottom] {
  margin-top: 6px !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #19b0b0;
}

.el-select .el-input .is-reverse {
  color: #28cccc;
}

.el-select .el-input__inner:focus {
  border-color: #19b0b0;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: rgba(234, 234, 234, 0.5);
  border-radius: 4px;
}

.el-select>.el-input>input {
  border-radius: 4px;
}

.el-select-dropdown__item {
  width: 91%;
  margin: 0 auto;
  padding-left: 9px !important;
}

.el-select-dropdown__item:hover {
  color: #19b0b0 !important;
  border-radius: 4px !important;
}

.el-select-dropdown[x-placement^=bottom] .popper__arrow {
  opacity: 0;
}

/* e ele 下拉框样式 */


/* s 日期控件 */
.el-picker-panel {
  box-shadow: 0px 2px 16px 0px rgba(222, 222, 222, 0.77);
  border-radius: 4px;
  border: solid 1px #28cccc;
  // overflow: hidden;
}

.el-picker-panel>.popper__arrow {
  opacity: 0;
}

.el-date-table td.available:hover {
  color: #28cccc;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 265px;
}

.el-date-editor .el-range__icon {
  color: #28cccc;
  font-size: 15px;
}

.el-date-editor .el-range-input {
  color: #333;
  margin-left: 0px !important;
  width: 35% !important;
}

.el-range-editor--mini .el-range-input {
  font-size: 14px;
}

.el-date-range-picker.has-sidebar {
  width: 748px;
}

.el-date-table__row {
  /* background-color: #f7f7f7; */
  overflow: hidden;
}

.el-date-table__row td {
  background-color: #f7f7f7;
}

.el-date-table tr:nth-child(2) td:first-child {
  border-radius: 4px 0 0 0;
}

.el-date-table tr:nth-child(2) td:last-child {
  border-radius: 0 4px 0 0;
}

.el-date-table tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

.el-date-table tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}

.el-date-table th {
  border-bottom: none;
}

.el-picker-panel__icon-btn:hover {
  color: #28cccc;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #28cccc;
}

.el-date-table td span {
  width: 22px;
  height: 19px;
  border-radius: 10px;
  line-height: 19px;
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: #ececec;
  color: #19b0b0;
}

.start-date>div>span,
.end-date>div>span {
  color: #fff !important;
}

.el-date-table td,
.el-date-table td div {
  height: 24px;
}

.el-date-table th {
  padding: 0;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: #28cccc !important;
}

.el-icon-date {
  background: url('../../assets/images/rili.png') center center no-repeat;
  background-size: 15px;
  position: absolute;
  right: 1px;
}

.el-icon-date:before {
  content: "替";
  font-size: 16px;
  visibility: hidden;
}

.el-range-editor--mini .el-range__close-icon {
   background: url('../../assets/images/riliclose.png') center center no-repeat;
  background-size: 15px;
  opacity: 0 !important;
}

.el-range-editor--mini:hover .el-range__close-icon {
  opacity: 1 !important;
}

.el-range__close-icon::before {
  content: "替";
  font-size: 16px;
  visibility: hidden;
}

.el-picker-panel__shortcut {
  color: #666;
}

.el-date-range-picker__header div {
  color: #666;
}

.el-picker-panel__shortcut {
  padding-left: 16px;
}

.el-picker-panel [slot=sidebar],
.el-picker-panel__sidebar {
  width: 98px;
}

.el-date-range-picker__content {
  padding-bottom: 20px;
}

.el-picker-panel__body {
  margin-left: 98px !important;
}

.el-picker-panel [slot=sidebar],
.el-picker-panel__sidebar {
  padding-top: 4px;
}

.el-picker-panel {
  color: #333;
}

.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #999;
}

.el-date-editor.el-input__inner {
  line-height: 28px !important;
  width: 264px!important;
}

.el-date-editor .el-range-separator {
  width: 8%;
}

.el-range-editor--mini .el-range-separator {
  line-height: 12px;
}

/* e 日期控件 */

// s修改光标颜色

input,
textarea {
  caret-color: #28cccc;
}

// e修改光标颜色
.el-message.newTip {
  background: #666666;
  color: #fff;
  border: none;
  min-width: 180px;
}

// .main-container {
//   margin: 0 30px;
// }

.row-panel {
  margin-bottom: 24px;
  padding: 24px;
  box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);
  border-radius: 4px;
  background: #fff;
}

.search-panel {
  padding: 16px 20px !important;
  font-size: 14px;
  color: #333;

  .search-item {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 14px 8px 0;
  }

  .search-label {
    margin-left: 12px;
    margin-right: 8px;
  }
}



.bottom_content {
  position: relative;
  padding: 6px 20px 25px 20px;
  margin-top: 20px;
  border-radius: 4px;
  background: white;
  box-shadow: 0px 2px 32px 0px rgba(222, 222, 222, 0.86);

  .orderStatusImg {
    margin-right: 4px;
  }

  .el-table th div {
    line-height: normal;
  }

  .list_panel::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #d2d2d3;
  }

  .list_panel::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(255, 255, 255, 0.1);
  }

  .list_panel::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    position: absolute;
  }

  .order_num {
    padding: 2px 5px;
    font-size: 12px;
    min-width: 15px;
    border-radius: 10px;
    text-align: center;
    color: white;
    position: absolute;
    top: 6px;
    left: 70px;
    background: #28CCCC;
    height: 17px;
    line-height: 15px;
  }

  .orderStatusTipPanel {
    position: absolute;
    line-height: 4px;
    white-space: nowrap;
    height: 280px;
    width: 164px;
    left: 116px;
    top: 113px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #000;
    font-size: 12px;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    z-index: 2;
    -webkit-transition: .2s;
    transition: .2s;

    .orderStatusTipPanel_item {
      font-size: 13px;
      margin-top: 7px;
      position: relative;
      color: #777;
      font-weight: 400;

      span {
        display: inline-block;
        margin-top: 0px;
        position: absolute;
        top: 8px;
        left: 56px;
      }
    }

    .mod {
      position: relative;
      min-width: 142px;
      padding: 10px 33px 3px 11px;
      height: 33px;
      z-index: 2;
      box-sizing: border-box;
      background: #fff;
    }

    .mod:after {
      position: absolute;
      content: '';
      top: -10px;
      transform: rotate(362deg);
      left: 49%;
      margin-left: -6px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
      z-index: 2;
    }
  }

  .negotiation_tips {
    border: 1px solid red;
    padding: 3px 7px;
    color: red;
    opacity: 0.65;
    font-size: 10px;
    transform: rotate(-39deg);
    position: absolute;
    left: -8px;
    top: 0px;
  }

  .imgBlack {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
