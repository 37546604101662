























































































.list {
  width: 100%;
}
