































































.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-active {
  transition: opacity 2.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.avue-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  padding: 20px 16px;
  height: 64px;
  line-height: 60px;
  background-color: #20222a;
  font-size: 20px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*color: rgba(255, 255, 255, 0.8);*/
  z-index: 1024;
  background-color: white;

  .icon {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    border: 1px solid #28CCCC;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 100%;
    font-size: 12px;
    text-align: center;
    color: #28CCCC;
    font-weight: bold;
  }

  .avue-logo_subtitle {
    .collapse_icon {
      cursor: pointer;
      font-size: 18px;
      transform: rotate(180deg) translateY(-6px);
      /*color: #28cccc;*/
      margin-left: -7px;
    }
  }

  .avue-logo_title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 7px 0 0 16px;
    text-align: left !important;

    .collapse_icon {
      cursor: pointer;
      font-size: 18px;
      color: #28cccc;
      margin-left: 34px;
      transform: translateY(-6px);
    }
  }

  &_title {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
  }

  &_subtitle {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    /*color: #fff;*/
  }
}

