.avue-crud .el-table--medium td:nth-child(1) {
  overflow: hidden;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #28cccc;
}

.el-date-table td.end-date span,
.el-date-table td.start-date.today span {
  color: white;
}
.el-date-table td.today span {
  color: #28cccc;
}

.avue-crud .el-table td .cell {
  font-size: 12px !important;
  color: #666666;
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: rgb(247, 249, 250);
}

.el-table td div {
  display: inline-flex;
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: rgb(247, 249, 250);
}

.el-table .descending .sort-caret.descending {
  border-color: #28cccc;
}

.el-table .ascending .sort-caret.ascending {
  border-color: #28cccc;
}

.el-table .sort-caret.ascending {
  display: inline-block;
  border-width: 1px 1px 0 0;
  border-color: #9d9d9d;
  border-style: solid;
  transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0) scale(1,.9) !important;
  position: absolute;
  top: 3px;
}

.el-table .sort-caret.descending {
  display: inline-block;
  border-width: 1px 1px 0 0;
  border-color: #9d9d9d;
  border-style: solid;
  transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0) scale(1,.9) !important;
  position: absolute;
  bottom: 6px;
}

.el-table {
  td,
  th {
    &.el-table-column--selection {
      .cell {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;

        .el-checkbox {
          margin-right: 0;
        }
      }
    }
  }
  .caret-wrapper {
    width:  8px !important;
    margin-left: 8px;
    .sort-caret {
      left: 0;
      height: 6px !important;
      width: 6px !important;
    }
  }
}


//表格样式  start
.table_template {
  font-size: 13.5px !important;
  color: #333333;
  margin: 15px 30px;
  padding-bottom: 40px;
  width: calc(100% - 60px);

  .search_panel {
    background: white;
    box-shadow: 0px 2px 32px 0px rgba(222, 222, 222, 0.86);
    padding: 23px 20px;
    border-radius: 4px;

    div {
      display: inline-block;
      margin-bottom: 1px;
    }

    .searchButton {
      margin-left: 14px;
    }

    .search_title {
      width: 59px;
      line-height: 27px;
      color: #444;
      margin-right: 5px;
    }
  }

  .bottom_content {
    position: relative;
    border-radius: 4px;
    padding: 15px 20px 25px 20px;
    margin-top: 20px;
    background: white;
    box-shadow: 0px 2px 32px 0px rgba(222, 222, 222, 0.86);;

    .scroll::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #d2d2d3;
    }

    .scroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(255, 255, 255, 0.1);
    }

    .scroll::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      position: absolute;
    }

    .scroll {
      width: 100%;
      overflow-x: auto;
    }
  }
}

//表格样式  end（后面优化后可删除）


//arud表格统一样式   startpadding: 11px 0;
.avue-crud{
  font-size: 13.5px!important;
  .el-table{
    thead{
      font-weight: 600;
      color: #333;
      border-bottom: 2px solid #28CCCD;
      th {
        background-color: #E7EAEF;
        padding: 10px 0;
        font-weight: 600;
        color: #333;
        border-bottom: 2px solid #28CCCD;
        border-right: 1px solid white;
      }
      th>.cell {
        //line-height: 30px;
      }
    }
  }
  .avue-crud__pagination{
    display: none;
    height: 34px;
    padding: 2px 5px;
    margin: 15px 0 20px 0;
    .el-pagination {
      position: absolute;
      left: 50%;
      top: 50%;
      right: unset;
      transform: translate(-50%,-50%);
      .el-pagination__sizes{
        float: right;
        .el-input--mini .el-input__inner {
          height: 33px !important;
          line-height: 33px !important;
        }
      }
    }
  }
}
// .SearchBar{
//   .searchButton {
//     height: 32px;
//     line-height: 31px;
//     margin-left: 17px!important;
//     border: none;
//     padding: 0 17.5px;
//     font-size: 13.5px;
//   }
// }
//arud表格统一样式   end

.el-table__body-wrapper::-webkit-scrollbar {
  width: 11px;
height: 11px;
}
//.el-table--scrollable-x .el-table__body-wrapper {
//  overflow-x: auto;
//  //overflow-y: auto;
//  //max-height: 534px;
//}

.el-table .el-table__fixed {
  height: calc(100% - 11px) !important;
}

.el-table .el-table__fixed-right {
  //height: calc(100% - 11px) !important;
  height:100% !important;
}

//.el-table__fixed-right{
//  right:11px!important
//}
//.el-table__fixed-right-patch{
//  width:11px!important
//}
