.el-scrollbar__bar.is-vertical {
  width: 12px;
}

.el-dropdown-menu__item {
  font-size: 12px !important;
  line-height: 28px !important;
}

.el-card.is-always-shadow {
  box-shadow: none;
  border: none !important;
}

.el-scrollbar__wrap {
  // padding: 10px 0;
  // box-sizing: border-box;
  // overflow-x: hidden;

  .el-scrollbar__view {
    height: auto;

    .avue-view {
      padding: 10px 1.74% 0;
    }
  }
}

.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu {
  border-right: none !important;
}

.el-menu--display,
.el-menu--display+.el-submenu__icon-arrow {
  display: none;
}

.el-dropdown-menu__item--divided:before,
.el-menu,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: transparent;
}

.el-message__icon,
.el-message__content {
  display: inline-block;
}

.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
  height: auto;
  overflow: hidden;
}

.el-dialog__wrapper {
  z-index: 2048;
}


.el-col {
  margin-bottom: 8px;
}

.el-main {
  padding: 0 !important;
}

.el-collapse-item__header {
  text-align: center;
  display: block;
}

// 重写el-table样式
.el-table {
  th {
    height: 48px;
    color: #333 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: center !important;
    border-bottom: 2px solid #31CBCF !important;
    border-right-color: #fff !important;
    background: #E7EAEE !important;
  }

  td {
    height: 45px;
    font-size: 12px;
    color: #666;
    border-right-color: #E8EBEE !important;
    border-bottom-color: #E8EBEE !important;
  }

  .el-table__cell {
    line-height: 1.2em !important;
    padding: 5px !important;
    text-align: center;

    .cell {
      padding: 0 !important;
      line-height: 1.2em !important;
    }
  }
}

// 重写el-table样式 end
// 重写el-dialog样式
.el-dialog__wrapper {
  display: flex;
  height: 100vh;
  padding: 58px 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .el-dialog {
    display: flex;
    flex-direction: column;

    width: 608px;
    margin: 0 !important;
    border-radius: 4px;
    box-shadow: 0px 6px 30px 0px rgba(51, 51, 51, 0.05);
    overflow: hidden;

    .el-dialog__header {
      flex: none;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 0 0 12px;
      box-sizing: border-box;
      background: #F7FBFC;

      .el-dialog__title {
        color: #666;
        font-size: 16px;
      }

      .el-dialog__headerbtn {
        width: 38px;
        height: 38px;
        top: 0;
        right: 0;
        font-size: 20px;

        .el-dialog__close {
          color: #ccc;
        }

        &:hover {
          .el-dialog__close {
            color: #19B0B0;
          }
        }
      }
    }

    .el-dialog__body {
      flex: 1;
      // margin: 20px;
      padding: 20px 40px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px;
        height: 6px;
        background-color: transparent;
        box-shadow: none;
      }

      &::-webkit-scrollbar-track {
        display: block;
        background-color: transparent;
        box-shadow: none;
      }

      /*滚动块*/

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #E5E5E5;
        box-shadow: none;
      }
    }

    .el-dialog__footer {
      flex: none;
      padding: 20px 0 50px;
      text-align: center;

      .z-btn {
        margin: 0 12px;
      }
    }
  }
}

// 重写el-dialog样式 end
