$main:#28cccc;

$color-bg-title:#e7eaee;

$color-text-grey:#999;

$color-border-grey:#eaeaea;

@mixin border-radius($radius...) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($shadows...) {
  -moz-box-shadow: $shadows;
  -webkit-box-shadow: $shadows;
  box-shadow: $shadows;
}

@mixin shadows-blue() {
  @include box-shadow(0px 2px 10px rgba(85, 195, 198, .56));
}

@mixin shadow-grey {
  @include box-shadow(0px 2px 32px rgba(222, 222, 222, .86));
}

// shadow-blue() {
//   box-shadow: 0 3px 10px rgba(85, 195, 198, .56);
// }

/*reset*/
.el-button--default {
  border-color: #E4E2E2;
  color: #999;

  &:hover {
    //color: #fff;
    //border-color: $main;
    //background: $main;
  }
}

.el-button--primary {
  color: #fff;
}

/*模块容器模板*/
// .main-template {
//   position: relative;
//   margin-bottom: 30px;
//   padding: 20px 30px;

//   .search_panel {
//     position: relative;
//     padding: 14px 30px;
//     @include border-radius(4px);
//     @include shadow-grey();
//     background: #fff;

//     .el-input {
//       width: 250px;
//       margin: 10px 14px 10px 0;

//       .el-input__inner {
//         height: 28px;
//         padding: 12px 15px;
//         color: $color-text-grey;
//         border-color: $color-border-grey;
//         box-sizing: border-box;

//         // &::placeholder {
//         //   color: $color-text-grey;
//         // }

//       }


//       .el-input__icon {
//         line-height: 26px;
//       }
//     }


//     .el-input {
//       width: 300px;

//       .el-input__inner {
//         padding: 0 15px;
//       }
//     }

//     .el-button {
//       height: 28px;
//       margin: 10px 14px 10px 0;
//       padding: 0 30px;
//       box-sizing: border-box;
//       font-size: 14px;

//       &:hover {
//         @include shadows-blue()
//       }
//     }

//     .autocomplete-search {

//       .el-input {

//         .el-input__inner {
//           padding: 0 30px;
//         }
//       }
//     }

//     .autocomplete-search-large {
//       .el-input {
//         width: 580px;
//       }

//     }
//   }

//   .container-template {
//     position: relative;
//     border-radius: 4px;
//     @include shadow-grey();
//     background: #fff;

//     &.container-template-pd {
//       padding: 23px;
//     }

//     &.mt {
//       margin-top: 24px;
//     }
//   }


//   /* 重置 tab样式，去掉common.scss定义的阴影 */
//   .el-tabs__nav-wrap {
//     box-shadow: none !important;

//     &:after {
//       display: none;
//     }
//   }


// }

/*tab模板*/
// .el-tabs-template {
//   .el-tabs__header {
//     margin: 0;
//   }

//   .el-tabs__header {
//     // @include shadow-grey();
//     box-shadow: 0px 2px 32px -8px rgba(222, 222, 222, .86); //cc

//     .el-tabs__nav-scroll {
//       margin: 0;
//       padding: 6px 15px 0;

//       .el-tabs__nav {
//         padding: 0 20px;
//       }

//       .el-tabs__item {
//         // padding: 0 16px;
//         box-sizing: border-box;
//         font-size: 16px;

//         &.is-active {
//           color: #19b0b0;
//         }
//       }

//       .el-tabs__active-bar {
//         padding: 0 16px;
//         box-sizing: content-box;
//       }
//     }
//   }

//   .el-tabs__content {
//     padding: 23px;

//   }

//   &.el-table--striped {
//     .el-table__body {
//       tr.el-table__row--striped {
//         td {
//           background: none;
//         }
//       }
//     }
//   }
// }


/*table 模板*/
.table-template {
  th {
    color: #333;
    border-right-color: #fff;
    text-align: center;
    background: #e7eaee;

    &.is-leaf {
      /*table 模板*/
      border-bottom: 2px solid #31cbcf;
    }

    div {
      font-weight: bold;

      &.cell {
        line-height: 1.2em;

      }
    }
  }

  td {
    padding: 10px 0;
    border-bottom-color: #e8ebee;
    border-right-color: #e8ebee;
    font-size: 12px;
    color: #666;
    text-align: center;

    .cell {
      display: block;
      padding: 0 8px;
      line-height: 1.4em;
    }

    .txt-discount {
      display: inline-block;
      padding: 0;
      min-width: 48px;
      white-space: nowrap;
      vertical-align: middle;
    }

    .txt-date {
      display: inline-block;
      width: 70px;
      color: #a0a0a0;
      white-space: nowrap;
    }

    .discountOld {
      text-decoration: line-through;
    }

    .el-tooltip {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;

      &.el-icon-question {
        vertical-align: -4px;
        margin-left: 4px;
      }
    }

    .el-button {
      position: relative;

      &.el-button--text {
        margin: 0 4px;
        padding: 6px 10px;
        color: #666;

        &:hover {
          color: #fff;
        }

        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          margin: -7px -5px 0 0;
          content: '';
          width: 1px;
          height: 14px;
          background: #eaeaea;
        }

        &:last-child:after {
          display: none;
        }

        +.el-button--text {
          margin-left: 4px;
        }

        &.is-disabled {
          &:hover {
            color: #ccc;
            box-shadow: none;
          }
        }
      }

      &.is-disabled {
        color: #ccc;

        &:hover {
          color: #ccc;
        }
      }
    }

    .icon-color-main {
      color: #19b0b0;
    }
  }

  .current-row {
    td {
      color: #000;

      &:before,
      &:after {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        @include shadow-grey();
        z-index: 1;
      }

      &:after {
        padding-left: 1px;
        box-sizing: content-box;
        left: -1px;
        border-left: 1px solid #fafafa;
        background: #fff;
        box-shadow: none;
        z-index: 2;
      }

      .cell {
        position: relative;
        z-index: 3;
      }
    }

  }
}


/*弹窗表单模板*/
.form-template {
  // width: 510px;
  margin: 0 auto;

  .el-form-item__label {
    width: 160px !important;
    padding-right: 20px;
    box-sizing: border-box;
    color: #999;
  }

  .el-form-item {
    margin: 0;

    .el-input {

      .el-input__inner {
        height: 32px;
        line-height: 30px;

        &[readonly] {
          color: #ccc;
        }

        &:focus {
          border-color: #DCDFE6 !important;
        }
      }

      &.input-discount {
        width: 120px;

        .el-input__suffix {
          i {
            font-style: normal;
          }
        }
      }
    }
  }

  .el-form-item__error {
    padding-top: 0;
    white-space: nowrap;
  }

  .el-button {
    margin-top: 19px;
  }
}

.el-dialog__header {
  background: #f7fbfc;
}

/*标题模板*/
.title-template {
  line-height: 1em;
  padding: 16px 12px;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  background-color: $color-bg-title;
}

.drawer-template {
  .el-drawer__body {
    overflow: auto;
  }

  &.drawer-template-pd {
    .el-drawer__body {
      padding: 0 20px 20px;
    }
  }
}

// .el-tooltip-z {
//   max-width: 332px;
//   padding: 7px 16px;
//   line-height: 1.5em;
//   box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.6);
//   font-size: 12px;

//   &.is-dark {
//     background: rgba(85, 85, 85, .9);

//     &[x-placement^=top] {
//       .popper__arrow {
//         border-top-color: rgba(85, 85, 85, .9);
//         &::after {
//           border-top-color: transparent;
//         }
//       }
//     }
//     &[x-placement^=bottom] {
//       .popper__arrow {
//         border-bottom-color: rgba(85, 85, 85, .9);
//         &::after {
//           border-bottom-color: transparent;
//         }
//       }
//     }
//     &[x-placement^=left] {
//       .popper__arrow {
//         border-left-color: rgba(85, 85, 85, .9);
//         &::after {
//           border-left-color: transparent;
//         }
//       }
//     }
//     &[x-placement^=right] {
//       .popper__arrow {
//         border-right-color: rgba(85, 85, 85, .9);
//         &::after {
//           border-right-color: transparent;
//         }
//       }
//     }
//   }
// }


.el-autocomplete-suggestion {
  li {
    span {
      position: relative;
      padding: 0 9px;

      &:after {
        position: absolute;
        content: '';
        height: 10px;
        width: 1px;
        top: 50%;
        right: -1px;
        margin-top: -5px;
        background: #e6e6e6;
      }

      &:last-child:after {
        display: none;
      }
    }

    .txt-main {
      color: $main;
    }
  }

  &.series-suggestion {
    li {
      span:after {
        display: none;
      }
    }
  }
}


.el-autocomplete-suggestion {
  padding-bottom: 6px;

  .empty,
  .loadMore {
    text-align: center;
    color: #999;
  }

  &.empty {
    li {
      cursor: default;

      &:hover {
        background: none;
      }
    }
  }

  &.hidden {
    display: none;
  }
}

.el-autocomplete-suggestion__wrap {
  max-height: 424px;
}

.el-dialog__header {
  padding: 11px 12px;
}

.el-dialog__headerbtn {
  top: 9px;
  right: 13px;
}
