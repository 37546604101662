


































































































































































.avue-top {
  height: 54px !important;
  line-height: 37px;

  .avue-breadcrumb_collapse {
    line-height: 57px;
  }

  .collapse_icon {
    background: url('../../../assets/images/order/arrow.png') no-repeat center center;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
    margin-top: 18px;
    cursor: pointer;
  }

  .avue-breadcrumb_collapse--right {
    transform: rotate(180deg);
    margin-left: 8px;
  }
}

